body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.c-json-string {
    font-size: 10px !important;;
    color: darkorange !important;
}

.c-json-number{
  font-size: 10px !important;
  color: darkgreen  !important;
}

.c-json-content .c-json-key {
    font-size: 12px !important;;
    color: darkblue !important;;
}