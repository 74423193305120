.header {
  text-align: center;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

* {font-size: 10px;}


.breakword{
  word-break: break-word;
}